import {defineRule} from 'vee-validate';
import i18n from '@/plugins/i18n';
const t = i18n.global.t;

defineRule('required', value => {
	if (typeof value === 'object' && value !== null) {
		return true;
	}

	if (!value || !value.length) {
		return t("form.error.validate.required");
	}

	return true;
});

defineRule('decimal', value => {
	if (value && value.length && !/^[-+]?[0-9]+\.?[0-9]*$/.test(value)) {
		return t("form.error.validate.numeric");
	}

	return true;
});

defineRule('min', (value, [limit]) => {
	if (!value || !value.length) {
		return true;
	}

	if (value.length < limit) {
		return t("form.error.validate.min", { limit });
	}

	return true;
});

defineRule('minValue', (value, [min]) => {
	if (Number(value) <= Number(min)) {
		return t("form.error.validate.minValue", { min });
	}

	return true;
});

defineRule('maxValue', (value, [max]) => {
	if (Number(value) >= Number(max)) {
		return t("form.error.validate.minValue", { max });
	}

	return true;
});

defineRule('confirmed', (value, [target], ctx) => {
	if (value === ctx.form[target]) {
		return true;
	}

	return t("form.error.validate.confirmed");
});

defineRule('isRequired', (value, [target]) => {
	if(target && target.length > 0) {
		if (!value || !value.length) {
			return t("form.error.validate.required");
		}

		return true;
	}

	return true;
});