<template>
	<div class="app-checkbox" :class='{"disabled": disabled, "readonly": readonly}'>
		<input
			type="checkbox"
			:checked="checked"
			:id="`checkbox-${id}`"
			@change="check"
		/>
		<label :for="`checkbox-${id}`">
			<span class='app-checkbox-check'></span>
			<span class='app-checkbox-title' v-html='label' v-if='label'></span>
		</label>
	</div>
</template>

<script>
import { computed } from "vue";

export default {
	emits: ["update:selected"],
	props: {
		label: {
			type: String
		},
		id: {
			type: [String, Number],
			required: true
		},
		selected: {
			type: [Boolean, Array],
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		}
	},
	setup(props, {emit}) {
		const checked = computed(() => {
			if (props.selected instanceof Array) {
				return props.selected.includes(props.id);
			}
			return props.selected === true;
		});

		function check($event) {
			let isChecked = $event.target.checked;

			if (props.selected instanceof Array) {
				const updatedIds = [...props.selected];

				checked.value
					? updatedIds.splice(updatedIds.indexOf(props.id), 1)
					: updatedIds.push(props.id);

				emit("update:selected", updatedIds);
			} else {
				emit("update:selected", isChecked);
			}
		}

		return { check, checked };
	}
};
</script>

<style lang='scss'>
	@import "@/styles/global/variables.scss";

	.app-checkbox {
		display: flex;
		font-size: 14px;
		user-select: none;
		input {
			display: none;
		}
		&-title {
			margin-left: 10px;
			&::first-letter {
				text-transform: uppercase;
			}
		}
		&-check {
			width: 44px;
			height: 24px;
			position: relative;
			background: $grey9;
			border-radius: 30px;
			padding: 2px;
			transition: background-color $transition;
			&:after,
			&:before {
				content: "";
				position: relative;
				display: block;
				width: 50%;
				height: 100%;
			}
			&:after {
				left: 0;
				border-radius: 50%;
				background: $white;
				transition: left $transition;
			}
			&:before {
				display: none;
			}
		}
		label {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		input:checked + label {
			.app-checkbox-check {
				background-color: $green;
				&:after {
					left: 50%;
				}
			}
		}
		&.disabled,
		&.readonly {
			pointer-events: none;
		}
		&.disabled label {
			color: rgba($black, .4);
			.app-checkbox-check {
				opacity: .24;
			}
		}
	}
</style>
